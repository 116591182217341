import { css } from 'astroturf';
import React from 'react';

import GithubBeatingHeart from './GithubBeatingHeart';
import PageHeader from './PageHeader';

const styles = css`
  .punchout {
    background: linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    font-weight: 700;
  }
  .nobold {
    font-weight: 300 !important;
  }
  .githubpage {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  .svg {
    fill: currentColor;
    height: 1rem;
    width: 1rem;
    vertical-align: baseline;
    transform: translateY(2px);
    margin-right: 4px;
  }

  .images {
    margin-bottom: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 150px;

    & div {
      flex: 0 1 128px;
      max-width: 128px;
      max-height: 150px;
    }

    & img {
      max-height: 150px;
    }

    & svg {
      margin: 0 1rem;
      color: #107db5;
      fill: #107db5;
      width: 3rem;
      height: 3rem;
    }
  }

  .button {
    position: relative;
    pointer-events: all;
    margin: 0 auto;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }
`;

function Github() {
  return (
    <>
      <PageHeader>
        <GithubBeatingHeart />
        <h1 className={styles.nobold}>
          <span className={styles.punchout}>Polypane</span> is part of the GitHub Student Developer Pack
        </h1>
      </PageHeader>
      <div className={[styles.githubpage, 'page childPadding'].join(' ')}>
        <p>
          The <a href="https://education.github.com/pack">GitHub Student Developer Pack</a> gives students free access
          to the best developer tools in one place so they can learn by doing.
        </p>
        <p>Get free access to Polypane for a year for non-commercial work as part of the program.</p>
        <p>
          <a href="https://dashboard.polypane.app/connect/github" className={styles.button}>
            <span>
              <svg className={styles.svg} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <title>GitHub icon</title>
                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
              </svg>
              Sign up with GitHub
            </span>
          </a>
        </p>
      </div>
    </>
  );
}

export default Github;
