import React, { Component } from 'react';
import About from '../components/About';
import Github from '../components/Github';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Polypane GitHub Student Developer Pack" keywords={['']} pathname={this.props.location.pathname} />
        <Github />
        <About nomargin />
      </Layout>
    );
  }
}

export default Page;
